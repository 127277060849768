import React, { FC } from "react";
import { Link } from "gatsby"
import Icon from '@mdi/react'
import { mdiArrowLeft } from '@mdi/js';

const h1Classes = `text-2xl m-2`
const h2Classes = `text-xl font-bold mt-3`
const h3Classes = `text-xl font-bold mt-3`
const h4Classes = `font-bold mt-2`
const ulClasses = `list-disc ml-4`

const ImprintPage: FC = () => {
  return (
    <main>
      <section id="imprint" className="text-primary font-gothic antialiased text-base font-normal leading-tight">
        <div className="container mx-auto px-10 py-20">
          <Link
            to="/"
            className="inline-flex items-center py-1 rounded text-inactive hover:text-primary focus:text-primary duration-500 text-lg font-medium mt-4 mb-4">
            <Icon
              className="hidden md:inline w-4 h-4 ml-1 mr-1"
              path={mdiArrowLeft}
              title="Back"
            />
            Back
          </Link>
          <h1 className={h1Classes}>
            Imprint
          </h1>

          <p>
            Stefan Junk<br />
            Michaelkirchplatz 24<br />
            10179 Berlin<br />
            Germany
          </p>

          <h2 className={h2Classes}>Contact</h2>
          <p>E-Mail: stefan[at]stefanjunk.com</p>

          <h3 className={h3Classes}>Widerspruch gegen Werbe-E-Mails</h3>
          <span>Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten Kontaktdaten zur &Uuml;bersendung von nicht ausdr&uuml;cklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.</span>

          <h3 className={h3Classes}>Objecting to Promotional Emails</h3>
          <span>The use of contact data published as part of the imprint obligation for the transmission of advertising and information material that has not been expressly requested is hereby contradicted. The site operators expressly reserve the right to take legal action in the event of unsolicited advertising being sent, such as spam e-mails.</span>

        </div>
      </section>
    </main>

  );
}

export default ImprintPage